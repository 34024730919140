<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4 mb-2" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <!-- RUT -->
      <ValidationProvider
        vid="rut"
        name="RUT"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="rut">RUT</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="rut"
            name="rut"
            v-model="user.rut"
            v-mask="['##.###.###-X', '#.###.###-X']"
            placeholder="Ingrese su RUT"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- NOMBRE -->
      <ValidationProvider
        vid="name"
        name="Nombre y apellido"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="name">Nombre y apellido</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="name"
            name="name"
            v-model="user.name"
            placeholder="Ingrese su nombre y apellido"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- TELEFONO -->
      <ValidationProvider
        vid="phone"
        name="Telefono"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="name">Telefono</label>
          <vue-tel-input
            :dropdownOptions="{
              showFlags: true,
              width: '200px',
              tabindex: 1,
            }"
            mode="international"
            :inputOptions="{
              placeholder: 'Telefono',
              maxlength: 15,
              showDialCode: true,
            }"
            v-model="user.phone"
          ></vue-tel-input>
          <div v-if="errors[0]">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </div>
      </ValidationProvider>
      <!-- EMAIL -->
      <ValidationProvider
        vid="name"
        name="Correo"
        rules="required|email"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="email">Correo Electrónico</label>
          <input
            type="email"
            v-model="user.email"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="email"
            aria-describedby="emailHelp"
            placeholder="Ingrese su correo electrónico"
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- PASSWORD -->
      <ValidationProvider
        vid="password"
        name="Contraseña"
        rules="required|confirmed:password_confirmation"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
            type="password"
            v-model="user.password"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="password"
            placeholder="Contraseña"
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- CONFIRM PASSWORD -->
      <ValidationProvider
        vid="password_confirmation"
        name="Confirmar contraseña"
        rules="required|min:8"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
            type="password"
            v-model="user.password_confirmation"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="password_confirmation"
            placeholder="Confirmar Contraseña"
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <!-- BUTTON ACTIONS -->
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <!-- <input type="checkbox" class="custom-control-input" :id="formType" />
          <label class="custom-control-label" :for="formType"
            >I accept <a href="#">Terms and Conditions</a></label
          > -->
        </div>
        <button
          type="submit"
          :disabled="isLoading"
          class="btn btn-primary float-right"
        >
          <span v-if="isLoading">
            <b-spinner small type="grow"></b-spinner>
            Cargando...
          </span>
          <span v-else> Registrarme </span>
        </button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          ¿Ya tienes una cuenta?
          <router-link to="/auth/login" class="iq-waves-effect pr-4">
            Iniciar Sesión
          </router-link>
        </span>
        <!-- <social-login-form></social-login-form> -->
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";

export default {
  name: "SignUpForm",
  data() {
    return {
      user: {
        rut: "",
        name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    ...mapActions({
      register: "register",
    }),
    async onSubmit() {
      const resp = await this.register(this.user);
      if (resp?.status == 200) {
        core.showSnackbar("success", "Usuario registrado exitosamente");
        this.$router.push("/auth/login");
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
};
</script>
<style>
.vue-tel-input {
  border-radius: 7px !important;
  border: 1px solid rgb(211, 211, 211);
  padding: 3px;
}
</style>

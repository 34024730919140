<template>
  <div>
    <h1 class="mb-0">Registro</h1>
    <p>Ingrese sus datos y luego haga click en el botón Registrarme.</p>
    <sign-up-form></sign-up-form>
  </div>
</template>
<script>
import SignUpForm from "./Components/SingUpForm.vue";

export default {
  name: "SignUp1",
  components: { SignUpForm }
};
</script>
